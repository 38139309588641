const { needles } = require('../config');
const { getRandomColor } = require('./color');
const URL = require("url-parse");
const formatDate = require('./formatDate');

module.exports = getMetaData;

function getMetaData(tweet, href, color){
  const url = new URL(href, {});
  const allNeedles = needles;
  const needleKeys = Object.keys(needles);
  const keywords = needleKeys.map((key)=>allNeedles[key]).concat(needleKeys);
  const colorH = color || getRandomColor(); 

  return {
    name: 'hope.fear.love',
    title: `@hopefearlove ♥ hope.fear.love`,
    tweetTitle: `@${tweet.tweet.user.screen_name} on ${formatDate(tweet.tweet.created_at)}`,
    description: "Twitter-based art project seeking to highlight what connects us. Emphasizing tweets that stand for themselves and express the feelings of real people.",
    og_type: "art",
    creator: "@gre_nish",
    site_handle: "@hopefearlove",
    tweetDescription: `${tweet.tweet.text}`,
    keywords: 'art,design,twitter,hashtag,nohashtag,'+keywords.join(','),
    author: 'Philipp Adrian, 2017, www.philippadrian.com',
    imageUrl: `http://${url.host}/image/${tweet.tweetId}/${colorH}/${tweet.needle}/${tweet.tweet.user.screen_name}.png`,
    tweetUrl: `${url.origin}/${tweet.needle}/${tweet.tweetId}/${colorH}`,
    color: colorH,
    currentUrl: href,
    baseUrl: `${url.origin}`
  };
}