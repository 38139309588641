import { homepage } from '../../../package.json';
import { NEW_DATA } from './NEW_DATA';
import { FETCHING } from './FETCHING';
import { Thunk } from '../redux.jsx';

export const FETCH_TWEETS = (
  needle: string,
  exclude?: string[],
): Thunk => (dispatch)=> {
  dispatch(FETCHING(needle, "FETCHING"));
  const excludeString = (exclude || []).join(";");
  fetch(`${homepage}api/latest/${needle}?exclude=${excludeString}`)
    .then((resp)=>resp.json())
    .then((result:Tweet[])=>{
      dispatch(NEW_DATA(result, needle));
    })    
    .catch((error)=>{
      setTimeout(()=>dispatch(FETCHING(needle, "ERROR", error)), 1000*10);
    })
};