declare global {
  interface NEW_ROUTE {
    type: "NEW_ROUTE",
    current : State["current"],
    url: string
  }
}

export const NEW_ROUTE = (current:State["current"], url:string): NEW_ROUTE =>{
  return {
    type: "NEW_ROUTE",
    current,
    url
  }
};

export const NEW_ROUTE_reducer:Reducer = (state, action) => {
  const { current, url } = action as NEW_ROUTE;
  let tweetQueue = state.tweetQueue;
  let displayedIds = JSON.parse(window.localStorage.getItem('displayedIds') || "{}");
  let displayCount = state.displayCount;
  if(current.needle && current.id) {
    let needleQueue = state.tweetQueue[current.needle] || [];
    const index = needleQueue.indexOf(current.id);
    if(index >= 0) {
      needleQueue = [...needleQueue];
      needleQueue.splice(index, 1);
      tweetQueue = {
        ...tweetQueue,
        [current.needle]: needleQueue
      }
    }
    displayedIds[current.needle] = displayedIds[current.needle] || [];
    if(displayedIds[current.needle].indexOf(current.id) < 0) {
      displayedIds[current.needle].unshift(current.id);
      displayedIds[current.needle].length = Math.min(displayedIds[current.needle].length, 2000);
    }
    displayCount++;
  }
  window.localStorage.setItem('displayedIds', JSON.stringify(displayedIds));
  return {
    ...state,
    displayCount,
    redirect: state.redirect === url ? undefined : state.redirect,
    tweetQueue,
    current
  };
}