import { NEW_ROUTE_reducer } from './actions/NEW_ROUTE';
import { NEW_DATA_reducer } from './actions/NEW_DATA';
import { FETCHING_reducer } from './actions/FETCHING';
import { REDIRECT_reducer } from './actions/REDIRECT';
import { IS_DROPDOWN_OPEN_reducer } from './actions/IS_DROPDOWN_OPEN';
import { createContext } from 'react';
import { needles } from '../shared/config';
import { LIKE_TWEET_reducer } from './actions/LIKE_TWEET';

declare global {
  interface Window { __INITIAL_STATE__: State|undefined; }

  type Actions = 
    | NEW_ROUTE
    | NEW_DATA
    | REDIRECT
    | IS_DROPDOWN_OPEN
    | LIKE_TWEET
    | FETCHING;

  interface State {
    current: {
      id?: string;
      needle?: string;
      color?: number;
    };
    displayCount: number,
    tweets: { [tweetId:string]: Tweet };
    tweetQueue: { [needle: string]: string[] };
    fetching: { [key:string]: FetchingStatus };
    needles: { [key:string]: string };
    redirect?: string;
    isDropdownOpen: boolean;
    likedTweetIds: string[];
  }

  interface Tweet {
    _id: string;
    tweetId: string;
    needle: string;
    tweet: {
      created_at: number;
      text: string;
      user: {
        screen_name: string;
      }
    };
    fetching: {};
  }

  interface Reducer { 
    (state:State, action:Actions): State;
  }

  interface StateContext {
    state: State,
    dispatch: (action:Actions)=>void
  }

  type FetchingStatus = "FETCHING"|"ERROR"|"SUCCESS";
}

export const Context:React.Context<any> = createContext({});

export type StateType = State;

export const initialState:State = (typeof window === "object" && window.__INITIAL_STATE__) || {
  current: {
    tweetId: undefined,
    needle: undefined,
    color: undefined,
  },
  displayCount: 0,
  tweets: {},
  tweetQueue: {},
  fetching: {},
  needles,
  redirect: undefined,
  isDropdownOpen: false,
  likedTweetIds: []
} as State;

export const reducer:Reducer = (state, action)=>{
  switch(action.type) {
    case "NEW_ROUTE": return NEW_ROUTE_reducer(state, action);
    case "NEW_DATA": return NEW_DATA_reducer(state, action);
    case "FETCHING": return FETCHING_reducer(state, action);
    case "REDIRECT": return REDIRECT_reducer(state, action);
    case "IS_DROPDOWN_OPEN": return IS_DROPDOWN_OPEN_reducer(state, action);
    case "LIKE_TWEET": return LIKE_TWEET_reducer(state, action);
    default: return state;
  }
}