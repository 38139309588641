declare global {
  interface REDIRECT {
    type: "REDIRECT",
    url: string
  }
}

export const REDIRECT = (url:string): REDIRECT =>{
  return {
    type: "REDIRECT",
    url
  }
};

export const REDIRECT_reducer:Reducer = (state, action) => {
  const { url } = action as REDIRECT;
  return {
    ...state,
    isDropdownOpen: false,
    redirect: url
  };
}