import { homepage } from '../../../package.json';
import { NEW_DATA } from './NEW_DATA';
import { FETCHING } from './FETCHING';
import { Thunk } from '../redux.jsx';

export const FETCH_TWEET = (
  id: string
): Thunk => (dispatch)=> {
  dispatch(FETCHING(id, "FETCHING"));
  fetch(homepage+"api/tweet/"+id)
    .then((resp)=>resp.json())
    .then((result:Tweet)=>{
      dispatch(NEW_DATA([result], id));
    })    
    .catch((error)=>{
      setTimeout(()=>dispatch(FETCHING(id, "ERROR", error )), 1000*10);
    })
};