declare global {
  interface FETCHING {
    type: "FETCHING",
    fetching: string,
    status: FetchingStatus
  }
}

export const FETCHING = (
  fetching:string, 
  status: FetchingStatus = "FETCHING",
  error?: string
): FETCHING =>{
  if(status === "ERROR" && error) {
    console.error(error);
  };
  return {
    type: "FETCHING",
    fetching,
    status
  }
};

export const FETCHING_reducer:Reducer = (state, action) => {
  const { fetching, status } = action as FETCHING;
  return {
    ...state,
    fetching: {
      ...state.fetching,
      [fetching]: status
    }
  };
}