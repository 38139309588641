import React, { SyntheticEvent } from 'react';
import { connect, ThunkDispatch } from "../redux";
import { createSelector } from 'reselect';
import { Context } from '../state';
import styled, { keyframes, css } from 'styled-components';
import Head from './Head';
import LikeButton from './LikeButton';
import NeedleDropdown from './NeedleDropdown';
import { createPortal } from 'react-dom';
import formatDate from '../../shared/utils/formatDate';
import { REDIRECT } from '../actions/REDIRECT';
import { getNextTweetColor } from '../../shared/utils/color';
import { IS_DROPDOWN_OPEN } from '../actions/IS_DROPDOWN_OPEN';
import { AllHtmlEntities } from 'html-entities';
const entities = new AllHtmlEntities();
declare const gtag: Function;


type ContextProps = {
  tweet: Tweet,
  displayCount: number,
  needles: State["needles"],
  nextId: string,
  liked: boolean,
  isDropdownOpen: boolean,
  REDIRECT: (url:string)=>void;
  IS_DROPDOWN_OPEN: (isOpen:boolean)=>void;
};

type OwnProps = {
  id: string,
  needle: string,
  color: number,
};

type Props = OwnProps & ContextProps;

function Tweet(props:Props){
  const needle = props.needles[props.needle];
  const text = props.tweet.tweet.text.slice(needle.length+1);
  const label = props.tweet.tweet.text.slice(0, needle.length);
  const captionLink=`https://twitter.com/${props.tweet.tweet.user.screen_name}/status/${props.tweet.tweetId}`;
  const rootPointerUp = (e:SyntheticEvent) => {
    e.preventDefault();

    !props.isDropdownOpen && gtag('event', 'click', {
      event_category: 'useraction',
      event_label: 'next',
      value: props.nextId || "undefined"
    });

    props.isDropdownOpen 
      ?props.IS_DROPDOWN_OPEN(false)
      :props.REDIRECT(`/${props.needle}/${getNextTweetColor(props.color as number)}/${props.nextId||""}`);
  };
  return (
    <Root 
      onMouseUp={rootPointerUp}
      onTouchEnd={rootPointerUp}
      tabIndex={0}
      isDropdownOpen={props.isDropdownOpen}
    >
      {createPortal(<Head 
        tweet={props.tweet} 
        color={props.color}
        href={window.location.href}
      />, document.head)}
      {props.displayCount <= 2 && <Arrow/>}
      <TweetOuter
        liked= {props.liked}
        isDropdownOpen={props.isDropdownOpen}
      >
        <TweetInner
          liked= {props.liked}
        >
          <NeedleDropdown needle={props.needle} label={label} color={props.color} />
          <span> {entities.decode(text)}</span>
        </TweetInner>
      </TweetOuter>
      <LikeButton 
        color={props.color} 
        id={props.id} 
        needle={props.needle} 
        liked={props.liked}
        username={props.tweet.tweet.user.screen_name}
      />     
      <Caption 
        onMouseUp={e=>{
          e.stopPropagation();
          gtag('event', 'click', {
            event_category: 'useraction',
            event_label: 'caption',
            value: props.id || "undefined"
          });
          window.open(captionLink);
        }}
        onTouchEnd={e=>{
          e.preventDefault();
          e.stopPropagation();
          gtag('event', 'click', {
            event_category: 'useraction',
            event_label: 'caption',
            value: props.id || "undefined"
          });
          window.location.href = captionLink;
        }}
      >
        {`Tweeted by @${props.tweet.tweet.user.screen_name}, ${formatDate(props.tweet.tweet.created_at)}`}
      </Caption>
    </Root>
  );
}
const Root = styled.div<{ isDropdownOpen: boolean}>`
  position:absolute;
  display:flex;
  height:100%;
  width:100%;
  flex-direction:column;
  align-items:flex-start;
  flex:none;
  color:#fff;
  & * {
    pointer-events:${({ isDropdownOpen })=>isDropdownOpen? "none":"auto"};
  }
`;

const TweetOuter = styled.div<{ isDropdownOpen: boolean; liked: boolean}>`
  display:flex;
  flex-grow:0;
  flex-shrink:0;
  position:relative;
  flex-flow:column;
  justify-content:center;
  align-items:center;
  height:77%;
  width:100%;
  z-index:${({ isDropdownOpen })=>isDropdownOpen? "3":"0"};
  user-select:auto;
  & * {
    user-select:auto;
  }
  transition: height 300ms 500ms;
`;

const TweetInner = styled.div<{ liked: boolean }>`
  max-width:700px;
  font-size:40px;
  line-height:58px;
  font-family:"Brandon Text W01 Thin",Helvetica Light,Arial,sans-serif;
  transition: margin-top 300ms 500ms;
  
  @media (max-width: 770px) { 
    margin: 40px;
    margin-top:${({ liked })=>liked? "40px":"70px"};
    overflow:visible;
    font-size:30px;
    line-height:46px;
    letter-spacing: 0.1px;
    font-family:"Brandon Text W01 Light", "Helvetica Light", Arial, sans-serif;
  }
`;

const Caption = styled.a`
  display:flex;
  align-self:center;

  margin:0;
  padding:0;
  outline:0;
  border:0;
  background: transparent;

  font-size: 14px;
  font-family: Brandon Text W01 Light,Helvetica Light,Arial,sans-serif;
  letter-spacing: .4px;
  margin-top: 35px;
  margin-bottom: 20px;
  color:#fff;
  cursor:pointer;

  opacity: .7;
  transition: opacity 50ms, margin 200ms;
  z-index:1;

  &:hover {
    opacity:1;
  }

  @media (max-height: 540px) { 
    margin-top: 10px;
  }
  @media (max-width: 770px) {
    margin-top: 0px;
  }
`;

const heartBeatAnimation = keyframes`
  from {
    transform: scale3d(1, 1, 1);
    opacity:0.3;
  }

  70% {
    transform: scale3d(1.2, 1.2, 1.2);
    opacity:0.7;
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity:0.3;
  }
`

const Arrow = styled.a`
  z-index:3;
  display:block;
  position:absolute;
  font-size:40px;
  line-height:40px;
  right:10px;
  top:0;
  user-select:none;
  tap-highlight-color: transparent;
  outline:0;
  cursor:pointer;
  padding:20px;
  transition: opacity 100ms;

  &:active {
    opacity:1;   
  }

  &:before {
    content:"\f3d3";
    display:inline-block;
    font-family:"Ionicons";
    speak:none;
    font-style:normal;
    font-weight:normal;
    font-variant:normal;
    text-transform:none;
    text-rendering:auto;
    line-height:1;
    font-smoothing:antialiased;
    user-select:none;
    tap-highlight-color: transparent;  
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-duration: 500ms;
    animation-delay: 300ms;
    animation-timing-function: ease;
    animation-name: ${heartBeatAnimation};
    opacity: 0.3;
  }

  @media (max-width: 770px) { 
    font-size:30px;
    right:0px;
    top:-10px;
    transform:translateY(0);
  }
`;

const selector = createSelector(
  (state:StateContext["state"], props: OwnProps) => state.tweets[props.id] as Tweet,
  (state:StateContext["state"]) => state.displayCount,
  (state:StateContext["state"]) => state.needles,
  (state:StateContext["state"]) => state.isDropdownOpen,
  (state:StateContext["state"], props: OwnProps) => state.likedTweetIds.includes(props.id),
  (state:State) => (
    (
      state.current.needle 
      && state.tweetQueue[state.current.needle]
      && state.tweetQueue[state.current.needle][0]
    )
    || undefined
  ),
  (tweet, displayCount, needles, isDropdownOpen, liked, nextId) => ({ tweet, displayCount, needles, isDropdownOpen, liked, nextId })
);

const actions = (dispatch:ThunkDispatch)=>({
  REDIRECT: ( url: string )=>dispatch(REDIRECT( url )),
  IS_DROPDOWN_OPEN: ( isOpen: boolean )=>dispatch(IS_DROPDOWN_OPEN( isOpen ))
})

export default connect(Context, selector, actions)(Tweet);

