module.exports = {
  
  "ip" : process.env.IP || process.env.OPENSHIFT_NODEJS_IP || '127.0.0.1',
  "port" : process.env.PORT || process.env.OPENSHIFT_NODEJS_PORT || 5000,
  "appName" : process.env.OPENSHIFT_APP_NAME || 'hope.fear.love',
  
  "needles" : {
    'hope' : 'I hope',
    'fear' : 'I fear',
    'love' : 'I love',
    'hate' : 'I hate',
    'want' : 'I want',
    'worry' : 'I worry',
    'wish' : 'I wish',
    'regret' : 'I regret',
    // 'need' : 'I need',
	  // 'will' : "i will",
    // 'why' : "why",
    // 'think' : "I think",
    // 'know' : 'I know',
    // 'am' : 'I am',
    // 'found' : 'I found',
    // 'everyTime' : "every time",
    // 'sometimes' : "sometimes i",
    // 'wonder' : "i wonder",
    // 'believe' : 'I believe',
  },
  
  "mongoDB" : process.env.MONGODB,
  
  "twitter" : {
    'consumer_key': process.env.TWITTER_CONSUMER_KEY,
    'consumer_secret': process.env.TWITTER_CONSUMER_SECRET,
    'access_token': process.env.TWITTER_ACCESS_TOKEN_KEY,
    'access_token_secret': process.env.TWITTER_ACCESS_TOKEN_SECRET
  },

  "twitter2": {
    'consumer_key': process.env.TWITTER_CONSUMER_KEY_2,
    'consumer_secret': process.env.TWITTER_CONSUMER_SECRET_2,
    'access_token': process.env.TWITTER_ACCESS_TOKEN_KEY_2,
    'access_token_secret': process.env.TWITTER_ACCESS_TOKEN_SECRET_2
  },

  "collectionLimits": {
    minNo: 5000,
    minTime: 1000*60*60*24,
    likeTime: 1000*60*60*6
  },

  "throttleDBUpdates": 1000*5
  
};