import React, {ReactNode } from 'react';
import { connect } from "../redux";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { createSelector } from 'reselect';
import { needles }  from '../../shared/config';
import { Context } from '../state';
import { getRandomColor } from '../../shared/utils/color';
import { NEW_ROUTE } from '../actions/NEW_ROUTE';

const needleKeys = Object.keys(needles);

type ContextProps = {
  state: State,
  dispatch: StateContext["dispatch"]
};
type OwnProps = {
  children: ReactNode
};
type Props = OwnProps & ContextProps ;

function MyRouter(props:Props){
  return (
    <Router>
      { props.state.redirect && <Redirect to={props.state.redirect} push={true} /> }
      <Route path="/:needle?/:color?/:id?" render={(route) => {
        const currentRoute = selectRoute((route.match.params || {}) as State["current"]);
        const expectedRoute = getExpectedRoute(currentRoute, props.state);
        if(currentRoute !== selectRoute(expectedRoute)) {
          return <Redirect to={`/${expectedRoute.needle}/${expectedRoute.color}/${expectedRoute.id || ''}`} />
        }
        if(currentRoute !== props.state.current) {
          props.dispatch(NEW_ROUTE(currentRoute, route.match.url));
        }
        return null;
      }}/>
      {props.children}
    </Router>
  );
}

const selectRoute = createSelector<
  State["current"], 
  string|undefined, 
  string|undefined, 
  number|undefined,
  State["current"]
>(
  (current) => current.needle,
  (current) => current.id,
  (current) => Number(current.color) || undefined,
  (needle, id, color)=>({ needle, id, color})
)

const selector = createSelector<
  State,
  State,
  { state: State }
>(
  (state) => state,
  (state) => ({ state })
);

function getExpectedRoute(
  currentRoute:State["current"], 
  state: State
):State["current"] {
  const expectedRoute:State["current"] = {};

  // NEEDLE 1
  // if we have needle in route 
  if(currentRoute.needle){
    expectedRoute.needle = currentRoute.needle;
  }
  // if we have needle in state 
  else if(state.current.needle){
    expectedRoute.needle = state.current.needle;
  }
  // if we dont have a needle or if it isnt valid, pick a random one
  if(!expectedRoute.needle || !needleKeys.includes(expectedRoute.needle)) {
    expectedRoute.needle = ["hope", "love", "wish"][Math.floor(Math.random()*3)];
  }

  // ID
  //if we have a route id
  if(currentRoute.id && currentRoute.id !== "undefined") {
    //and it matches state
    if(currentRoute.id === state.current.id) {
      //we take current id
      if(
        // if we have tweet data
        state.tweets[currentRoute.id]
        // or there was no error fetching it (yet)
        || state.fetching[currentRoute.id] !== "ERROR"
      ) {
        expectedRoute.id = currentRoute.id;
      }
      // otherwise we take next one in queue for current needle
      else if(state.tweetQueue[expectedRoute.needle]) {
        expectedRoute.id = state.tweetQueue[expectedRoute.needle][0] || "";
      }
      else {
        expectedRoute.id = "";
      }
    }
    //if it doesnt match state we keep it to update state
    else {
      expectedRoute.id = currentRoute.id;
    }
  }
  // //if we have current id in state and it wasn't displayed, we keep it
  // else if(state.current.id && !state.displayedIds[state.current.id]){
  //   expectedRoute.id = state.current.id;
  // }
  // otherwise get new id from queue
  else {
    expectedRoute.id = 
      (
        state.tweetQueue[expectedRoute.needle] 
        && state.tweetQueue[expectedRoute.needle][0]
      )
      || undefined;
  }

  // NEEDLE 2
  // if we have a tweet we update needle to match
  if(expectedRoute.id && state.tweets[expectedRoute.id]) {
    expectedRoute.needle = state.tweets[expectedRoute.id].needle;
  }

  // COLOR
  // if we have color in route 
  if(currentRoute.color){
    expectedRoute.color = currentRoute.color;
  }
  // if we have color in state 
  else if(state.current.color){
    expectedRoute.color = state.current.color;
  }
  // otherwise pick a random one
  else {
    expectedRoute.color = getRandomColor();
  }
  return expectedRoute
}

export default connect(Context, selector)(MyRouter);