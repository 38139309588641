declare global {
  interface IS_DROPDOWN_OPEN {
    type: "IS_DROPDOWN_OPEN",
    isOpen: boolean
  }
}

export const IS_DROPDOWN_OPEN = (isOpen:boolean): IS_DROPDOWN_OPEN =>{
  return {
    type: "IS_DROPDOWN_OPEN",
    isOpen
  }
};

export const IS_DROPDOWN_OPEN_reducer:Reducer = (state, action) => {
  const { isOpen } = action as IS_DROPDOWN_OPEN;
  return {
    ...state,
    isDropdownOpen: isOpen
  };
}