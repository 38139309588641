import React, { useReducer, useMemo, useEffect } from 'react';
import { reducer, initialState, Context } from "./state" 
import Router from './components/Router';
import Root from './components/Root';
import { FETCH_TWEETS } from './actions/FETCH_TWEETS';
import { FETCH_TWEET } from './actions/FETCH_TWEET';
import { useThunk } from './redux';


function App() {
  // const stateReducer = useReducer(reducer, initialState);
  const [ state, dispatch ] = useThunk(
    useReducer(reducer, initialState)
  );

  //fetch data
  useEffect(()=>{
    const currentId = state.current.id;
    const currentNeedle = state.current.needle;
    if(
      currentNeedle
      && (
        !state.tweetQueue[currentNeedle] 
        || state.tweetQueue[currentNeedle].length <= 2
      )
      && state.fetching[currentNeedle] !== "FETCHING"
    ) {
      const displayedIds = JSON.parse(window.localStorage.getItem('displayedIds')||"{}");
      const excludeIds = [ 
        ...(state.tweetQueue[currentNeedle] || []), 
        ...(displayedIds[currentNeedle] || []),
      ];
      if(currentId) excludeIds.push(currentId);
      dispatch(FETCH_TWEETS(currentNeedle, excludeIds));
    }
    if(
      currentId 
      && !state.tweets[currentId] 
      && state.fetching[currentId] !== "FETCHING"
    ) {
      dispatch(FETCH_TWEET(currentId));
    }
  }, [state, dispatch]);

  // console.log(state);

  return (
    <Context.Provider value={{ state, dispatch }}>
      {useMemo(()=>(
        <Router>
          <Root />
        </Router>
      ), [])}
    </Context.Provider>
  );
}

export default App;
