declare global {
  interface NEW_DATA {
    type: "NEW_DATA",
    tweets: Tweet[],
    fetching: string
  }
}

export const NEW_DATA = (tweets: Tweet[], fetching: string): NEW_DATA =>{
  return {
    type: "NEW_DATA",
    tweets,
    fetching
  }
};

export const NEW_DATA_reducer:Reducer = (state, action) => {
  const { tweets, fetching} = action as NEW_DATA;
  const newTweetQueue:State["tweetQueue"] = {};
  const newTweets:State["tweets"] = {};
  const displayedIds = JSON.parse(window.localStorage.getItem('displayedIds')||"{}");
  
  Object.keys(state.tweetQueue).forEach((needle)=>{
    newTweetQueue[needle] = [...state.tweetQueue[needle]];
  });  
  
  tweets.forEach((tweet)=>{
    newTweets[tweet.tweetId] = tweet;
    newTweetQueue[tweet.needle] = newTweetQueue[tweet.needle] || [];
    displayedIds[tweet.needle] = displayedIds[tweet.needle] || [];
    if(
      !displayedIds[tweet.needle].includes(tweet.tweetId)
      && state.current.id !== tweet.tweetId
      && !newTweetQueue[tweet.needle].includes(tweet.tweetId)
    ) {
      newTweetQueue[tweet.needle].push(tweet.tweetId);
    }
  });

  return {
    ...state,
    tweetQueue: newTweetQueue,
    fetching: {
      ...state.fetching,
      [fetching]: "SUCCESS"
    },
    tweets: {
      ...state.tweets,
      ...newTweets
    },
  };
}