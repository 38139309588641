import { homepage } from '../../../package.json';
import { Thunk } from '../redux.jsx';

declare global {
  interface LIKE_TWEET {
    type: "LIKE_TWEET",
    id: string
  }
}

export const LIKE_TWEET = (
  needle: string,
  id: string,
): Thunk => (dispatch)=> {
  fetch(`${homepage}api/like/${id}/${needle}`);
  dispatch({
    type: "LIKE_TWEET",
    id
  });
};

export const LIKE_TWEET_reducer:Reducer = (state, action) => {
  const { id } = action as LIKE_TWEET;
  state.likedTweetIds.push(id);
  return {
    ...state,
    likedTweetIds: [...state.likedTweetIds]
  };
}