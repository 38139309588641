import React, { SyntheticEvent } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { getString } from '../../shared/utils/color';
import { Context } from '../state';
import { connect, ThunkDispatch } from '../redux';
import { LIKE_TWEET } from '../actions/LIKE_TWEET';
declare const gtag: Function;

type OwnProps = {
  color: number
  id:string
  needle:string
  username: string,
  liked: boolean
};

type DispatchProps = {
  LIKE_TWEET: ( id: string, needle: string )=>void
}

type Props = OwnProps & DispatchProps;

function LikeButton(props:Props){
  const { liked, needle, id, username, color, LIKE_TWEET } = props;
  const replyLink = `https://twitter.com/intent/tweet?in_reply_to=${id}&text=me%20too!&related=hopefearlove&url=https://hope.fear.love/image/${id}/${color}`;
  const shareLink = `https://twitter.com/intent/tweet?via=hopefearlove&related=hopefearlove&url=https://hope.fear.love/image/${id}/${color}`;
  const likePointerUp = (e:SyntheticEvent)=>{
    e.stopPropagation();
    e.preventDefault();
    gtag('event', 'click', {
      event_category: 'useraction',
      event_label: 'like',
      value: id || "undefined"
    });
    LIKE_TWEET(needle, id);
  };
  return (
    <Container>
      <LikeButtonLayer liked={liked}>
        <Like 
          highlightColor={getString(color)} 
          liked={liked} 
          onMouseUp={likePointerUp}
          onTouchEnd={likePointerUp}
        >
          <HeartIconAnimated liked={liked}/>
        </Like>
      </LikeButtonLayer>
      <InteractionButtonLayer liked={liked}>
      <Button 
          liked={liked} 
          highlightColor={getString(color)} 
          onMouseUp={(e)=>{
            e.stopPropagation();
            e.preventDefault();
            gtag('event', 'click', {
              event_category: 'useraction',
              event_label: 'share',
              value: id || "undefined"
            });
            window.open(shareLink, "", "width=500px,height=520px,scrollbars=no");
          }}
          onTouchEnd={(e)=>{
            e.stopPropagation();
            e.preventDefault();
            gtag('event', 'click', {
              event_category: 'useraction',
              event_label: 'share',
              value: id || "undefined"
            });
            window.location.href=shareLink;
          }}
        >
          <HeartIcon /> <span>share</span> the feeling!
        </Button>
        
        <Button 
          liked={liked} 
          highlightColor={getString(color)} 
          onMouseUp={(e)=>{
            e.stopPropagation();
            e.preventDefault();
            gtag('event', 'click', {
              event_category: 'useraction',
              event_label: 'reply',
              value: id || "undefined"
            });
            window.open( replyLink, "", "width=500px,height=520px,scrollbars=no");
          }}
          onTouchEnd={(e)=>{
            e.stopPropagation();
            e.preventDefault();
            gtag('event', 'click', {
              event_category: 'useraction',
              event_label: 'reply',
              value: id || "undefined"
            });
            window.location.href=replyLink;
          }}
        >
          <ReplyIcon /> <span>reply to</span>  @{username}
        </Button>

      </InteractionButtonLayer>
    </Container>
  );
}

const heartFadeOutUpAnimation = keyframes`
  from {
    opacity: 1;
    color:inherit;
  }
  50% {
    color:white;
  }
  to {
    opacity: 0;
    color:white;
  }
`

const heartBeatAnimation = keyframes`
  from {
    transform: scale3d(1, 1, 1);
  }

  70% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
`

const heartSwingAnimation = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }

  20% {
    transform: rotate3d(0, 0, 1, 20deg) scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: rotate3d(0, 0, 1, -20deg) scale3d(1.2, 1.2, 1.2);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg) scale3d(1.3, 1.3, 1.3);
  }

  80% {
    transform: rotate3d(0, 0, 1, -10deg) scale3d(1.4, 1.4, 1.4);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg) scale3d(1.5, 1.5, 1.5);
  }
`;

const interactionInAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale3d(0.8, 0.8, 0.8);
  }
  90% {
    opacity: 1;
    transform: scale3d(1.05, 1.05, 1.05);
  }
`;

const interactiveInAnimationDuration = 250;
const interactiveInAnimationDelay = 500;

const Container = styled.div`
  position:relative;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
`

const LikeButtonLayer = styled.div<{ liked: boolean }>`
  position: absolute;
  display: flex;
  z-index: ${({liked})=>liked ? 0 : 2}
  justify-content: center;
`

const InteractionButtonLayer = styled.div<{ liked: boolean }>`
  position:relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  transition: opacity .25s ${interactiveInAnimationDelay}ms, margin ${interactiveInAnimationDuration*0.9}ms ${interactiveInAnimationDelay}ms;
  flex-direction:colum;
  align-items:center;

  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: ${interactiveInAnimationDuration}ms;
  animation-delay: ${interactiveInAnimationDelay}ms;
  animation-timing-function: ease;

  visibility: ${({liked})=>liked ? "visible" : "hidden"};
  pointer-events: ${({liked})=>liked ? "auto" : "none"}
  animation-name: ${({liked})=> liked ? css`${interactionInAnimation}`: ""};
  margin-bottom: 15px;
  
  @media (max-width: 770px) {
    margin-bottom: ${({liked})=> liked ? "25px":"0px"};
    flex-direction:column;
  }
`


const Button = styled.button<{ highlightColor: string; liked: boolean }>`
  position:relative;  
  font-size: 16px;
  font-family: Brandon Text W01 Medium,Helvetica,Arial,sans-serif;
  color: #fff;
  padding: 5px 12px 6px 35px;
  transition: color .25s, background-color .25s, margin ${interactiveInAnimationDuration*0.9}ms ${interactiveInAnimationDelay}ms;
  user-select: none;
  cursor: pointer;
  border-radius:3px;
  border:none;
  outline:none;
  background: transparent;
  color: white;
  pointer-events: auto;
  margin:0px 10px;

  & span {
    font-family:"Brandon Text W01 Thin",Helvetica Light,Arial,sans-serif;
  }

  &:hover {
    background:rgba(255,255,255, 0.9);
    color: ${({highlightColor})=>highlightColor}
  }

  &:active {
    background:rgba(255,255,255, 0.7);
  }

  @media (max-width: 770px) {
    margin: ${({liked})=>liked ? "7px 10px" : "0px 10px"};
  }
`
const Like = styled(Button)`
  height:33px;
  width:39px;
  padding:0px;
  margin-bottom:10px;

  background:${({liked})=>liked ? "transparent !important" : "transparent"};
  color: ${({liked, highlightColor })=>liked ? `${highlightColor} !important` : "white"};
  pointer-events: ${({liked})=>liked ? `none` : `auto`};
`;

// const Reply = styled(Button)`
//   background:rgba(255,255,255, 0.7);
//   color: ${({highlightColor})=>highlightColor}
// `;

const Icon = styled.span`
  display: block;
  font-size: 18px;
  position: absolute;
  left: 12px;
  bottom: 4px;

  &::before {
    position:absolute;
    overflow:hidden;
    bottom:3px;
    width:16px; 
    display: block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
`

const ReplyIcon = styled(Icon)`
  font-size: 23px;

  left: 12px;
  bottom: 2px;

  &::before {
    content: "\f21e";
  }
`;

const FollowIcon = styled(Icon)`
  left: 10px;
  bottom:4px;

  &::before {
    content: "\f243";
  }
`;

const HeartIcon = styled(Icon)`
  &::before {
    content: "\\F388";
  }
`;


const HeartIconAnimated = styled(HeartIcon)<{liked:boolean}>`
  height: ${({liked})=> liked ? "100px": ""};

  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-duration: 1000ms;
  animation-name: ${({liked})=> liked ? css`${heartFadeOutUpAnimation}`: ""};
  
  animation-timing-function: ease;

  transform: ${({liked})=> liked 
    ? `translate3d(${Math.random()*200-100}px, -100%, 0) rotate3d(0, 0, 1, -20deg)`
    : `translate3d(0, 0, 0)`
  };
  transition: transform 1000ms ease-out;

  &::before {
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-duration: ${({liked})=> liked ? "2000ms": "500ms"};
    animation-delay: ${({liked})=> liked ? "0": "300ms"};
    animation-timing-function: ease;
    animation-name: ${({liked})=> liked ? css`${heartSwingAnimation}`: css`${heartBeatAnimation}`};
  }
`;

const actions = (dispatch:ThunkDispatch): DispatchProps =>({
  LIKE_TWEET: ( ...args )=>dispatch(LIKE_TWEET( ...args )),
})

export default connect(Context, null, actions)(LikeButton);