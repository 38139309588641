import React, { 
  useMemo, 
  useEffect, 
  useContext, 
  useState,
  useCallback,
  Dispatch,
} from 'react';
import { ReactComponentLike } from 'prop-types';

///////////////////////////////////////////////////////////////////////////////

// const Context:React.Context<any> = createContext({});

// export const Provider = ({
//   reducer,
//   initialState,
//   children
// }:{
//   reducer: Reducer<any, any>
//   initialState: any,
//   children: ReactNode
// }) => {
//   const [ state, dispatch ] = useReducer(reducer, initialState);
//   return (
//     <Context.Provider value={{ state, dispatch }}>
//     {useMemo(()=>(<>{children}</>), [])}
//     </Context.Provider>
//   );
// }

///////////////////////////////////////////////////////////////////////////////

export type Thunk = (dispatch: ThunkDispatch, getState:()=>State)=>void;
export type ThunkDispatch = (action:Actions|Thunk)=>void;

export function useThunk([ state, dispatch ]: [ State, Dispatch<Actions>] ){
  const containerRef:{ state?: State } = useMemo(()=>({}), []);
  containerRef.state = state;
  const thunkDispatch: ThunkDispatch = useCallback((action:Actions|Thunk)=>{
    switch(typeof action) {
      case "object": {
        dispatch(action as Actions);
        break;
      }
      case "function": {
        const thunk = action as Thunk;
        thunk(thunkDispatch, ()=>containerRef.state as State);
        break;
      }
    }
  }, [ containerRef, dispatch ]);

  return [ state, thunkDispatch ] as [ State, ThunkDispatch ];
}

///////////////////////////////////////////////////////////////////////////////

export const connect = (
  Context:React.Context<any>,
  selector:((
    context:any,
    props: any,
    prevSelection: any
  )=>any)|null,
  actions?: any
)=>(
  Component:ReactComponentLike
)=>(
  props:any
)=>{
  const currentSelector = selector || (()=>undefined);
  const { state, dispatch } = useContext(Context);
  const [ selection, updateSelection ] = useState(()=>currentSelector(state, props, null));
  const newSelection = currentSelector(state, props, selection);
  const dispatches = useMemo(()=>{
    switch(typeof actions){
      case "function": return actions(dispatch, props);
      case "object": return Object.entries(actions as {[key:string]: ()=>void})
        .reduce((dispatches, [key, action ])=>{
          dispatches[key] = ()=>dispatch(action());
          return dispatches;
        }, {} as {[key:string]: ()=>void});
      case "undefined": return { dispatch };
    }
  }, [ dispatch, props]);
  useEffect(
    ()=>updateSelection(newSelection), 
    [newSelection]
  );
  return useMemo(()=>(
    <Component {...props} {...selection} {...dispatches} />
  ), [selection, props, dispatches]);
}