import React, { ReactChild } from "react";
import getMetaData from "../../shared/utils/getMetaData";
import { getString } from "../../shared/utils/color";

type ContextProps = {};
type OwnProps = {
  href: string;
  tweet?: Tweet;
  color?: number; 
  children?: ReactChild[];
};

type Props = OwnProps & ContextProps ;

function Head(props:Props){
  const tweet = props.tweet;
  if(! tweet){
    return (
      React.createElement(React.Fragment, null,
        React.createElement("title", { dangerouslySetInnerHTML: { __html: "hope.fear.love" } }),
      )
    );
  }
  const meta = getMetaData(tweet, props.href, props.color);

  let favIcon = null;
  const font = `28px "Ionicons"`;
  if(props.color && typeof document !== "undefined" && (
    // @ts-ignore
    !document.fonts || document.fonts.check(font) 
  )) {

    const canvas = document.createElement("canvas");
    canvas.width = 32;
    canvas.height = 32;

    const context = canvas.getContext("2d")!;
    context.imageSmoothingEnabled = false;

    // context.fillStyle = "#000";
    // context.fillRect(0,0,32,32);

    context.fillStyle = getString(props.color);
    context.font = font;
    const heart = '';
    const heartSize = context.measureText(heart);      
    context.fillText(heart, 17-heartSize.width/2, 27);

    favIcon = canvas.toDataURL();
  }

  return (
    React.createElement(React.Fragment, null,
      React.createElement("meta", { name: "robots", content: "index, follow" }),
      React.createElement("meta", { name: "description", content: meta.description }),
      React.createElement("meta", { charSet: "utf-8" }),
      React.createElement("meta", { name:"viewport", content: "width=device-width, initial-scale=1" }),
      React.createElement("meta", { name: "twitter:card", content: "summary_large_image" }),
      React.createElement("meta", { name: "twitter:site", content: meta.site_handle }),
      React.createElement("meta", { name: "twitter:creator", content: meta.creator }),
      React.createElement("meta", { name: "twitter:title", content: meta.tweetTitle }),
      React.createElement("meta", { name: "twitter:description", content: meta.tweetDescription }),
      React.createElement("meta", { name: "twitter:image", content: meta.imageUrl }),
      React.createElement("meta", { property: "og:title", content: meta.title }),
      React.createElement("meta", { property: "og:type", content: meta.og_type }),
      React.createElement("meta", { property: "og:url", content: meta.currentUrl }),
      React.createElement("meta", { property: "og:image", content: meta.imageUrl }),
      React.createElement("meta", { property: "og:site_name", content: meta.name }),
      React.createElement("title", { dangerouslySetInnerHTML: { __html: meta.title } }),
      (favIcon && React.createElement("link", { rel: "icon", href: favIcon })),
      // Dont deploy this
      // React.createElement("link", { 
      //   "data-preserve": true, 
      //   rel: "stylesheet", 
      //   href: "/fonts/fonts.css" 
      // }),
      props.children
    )
  );
}

export default Head;

