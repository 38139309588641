import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { getString, getNextNeedleColor } from '../../shared/utils/color';
import { connect, ThunkDispatch } from '../redux';
import { Context } from '../state';
import { createSelector } from 'reselect';
import { REDIRECT } from '../actions/REDIRECT';
import { IS_DROPDOWN_OPEN } from '../actions/IS_DROPDOWN_OPEN';
declare const gtag: Function;

type OwnProps = {
  needle: string
  color: number
  label: string
};

type ContextProps = {
  needles: State["needles"];
  open: boolean;
  REDIRECT : (url:string)=>void;
  IS_DROPDOWN_OPEN : (isOpen:boolean)=>void;
};

type Props = OwnProps & ContextProps;

function NeedleDropdown(props:Props){
  const onDropdownPointerDown = (e:SyntheticEvent)=>{ 
    e.stopPropagation(); 
    e.preventDefault(); 
    props.IS_DROPDOWN_OPEN(!props.open);
    gtag('event', 'click', {
      event_category: 'useraction',
      event_label: 'dropdown',
      value: "open",
    });
  };
  const createNeedlePointerDown = (key:string)=>(e:SyntheticEvent)=>{
    e.stopPropagation();
    e.preventDefault();
    gtag('event', 'click', {
      event_category: 'useraction',
      event_label: 'needle',
      value: key,
    });
    props.REDIRECT(`/${key}/${getNextNeedleColor(props.color as number)}/`);
  }
  const preventPointerEvent = (e:SyntheticEvent)=>{
    e.stopPropagation()
    e.preventDefault()
  }
  return (
    <Container 
      open={props.open} 
      onMouseDown={preventPointerEvent}
      onTouchStart={preventPointerEvent}
      onMouseUp={onDropdownPointerDown} 
      onTouchEnd={onDropdownPointerDown} 
    >
      <ArrowContainer>
        <Arrow open={props.open} />
      </ArrowContainer>
      <Line />
      {props.label}
      <Dropdown open={props.open} highlightColor={getString(props.color)}>
        {Object.keys(props.needles)
          .filter((key)=>key !== props.needle)
          .map((key)=>(
            <li 
              key={key} 
              onMouseDown={preventPointerEvent}
              onTouchStart={preventPointerEvent}
              onMouseUp={createNeedlePointerDown(key)} 
              onTouchEnd={createNeedlePointerDown(key)} 
            >
              <button>
                <span>{props.needles[key]}</span>
              </button>
            </li>
      ))}
      </Dropdown>
    </Container>
  );
}

const Line = styled.span`
  position: absolute;
  top: 53px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  z-index: 2;
  opacity: 0;
  transition: opacity .1s;

  @media (max-width: 770px) {
    top: 41px;
  }
`;

const ArrowContainer = styled.span`
  position:absolute;
  top:0px;
  height:100%;
  width:37px;
  left:-37px;

  @media (max-width: 770px) {
    width:32px;
    left: -32px;
  }
`;

const Arrow = styled.span<{ open: boolean }>`
  position: absolute;
  display: block;
  top: 50%;
  margin-top:-5px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff;
  opacity: ${({ open })=> open ? "1": "0.5"};
  transform: ${({ open })=> open ? "rotate(-180deg)": "rotate(0deg)"};
  transform-origin: 50% 30%;
  transition: transform .3s,opacity .1s;
  
  @media (max-width: 770px) {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fff;
  }
`;

const Container = styled.div<{ open: boolean }>`
  font-family: Brandon Text W01 Medium, Helvetica, Arial, sans-serif;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding:5px 0px 10px 0px;
  margin:-5px 0px -10px 0px;
  z-index:2;

  pointer-events:auto !important;
  &:hover ${Line} {
    opacity: ${({ open })=> open ? "0" : "1" };
  }
  &:hover ${Arrow} {
    opacity: 1;
  }
`
const Dropdown = styled.ul<{ highlightColor: string, open: boolean }>`
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: visible;
  width: 100%;
  top: 53px;
  pointer-events:${({ open })=> open ? "auto !important" : "none !important" };
  & * {
    pointer-events:${({ open })=> open ? "auto !important" : "none !important" };
  }

  @media (max-width: 770px) {
    top: 41px;
  }

  & li {
    position: relative;
    line-height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    text-align: left;
    display: block;
    overflow: visible;
    background-color: #fff;
    opacity: .85;
    transition: height .3s,border-bottom .3s,opacity .1s;
 
    height: ${({ open })=> open ? "42px" : "0" };
    border-bottom: ${({ open, highlightColor })=> open ? `4px solid ${highlightColor}` : "0" };

    &:hover {
      opacity:.95;
    }

    &:active {
      opacity:1;
    }

    @media (max-width: 770px) {
      height: ${({ open })=> open ? "35px" : "0" };
      border-bottom: ${({ open, highlightColor })=> open ? `3px solid ${highlightColor}` : "0" };  
    }
  }
  
  & button {
    line-height: 0;
    overflow: hidden;
    position:relative;
    height:100%;
    border:0;
    padding: 0;
    background:transparent;
    outline:none;
    text-decoration:none;
  }

  & span {
    color: ${({highlightColor})=>highlightColor};
    padding: 5px 7px 5px 5px;
    display: block;
    background-color: #fff;
    border:0;
    font-size: 30px;
    line-height: 32px;
    cursor:pointer;
    font-family: "Brandon Text W01 Medium", Helvetica, Arial, sans-serif;

    @media (max-width: 770px) {
      padding: 4px 7px 4px 5px;
      font-size: 21px;
      line-height: 27px;
    }
  }
`

const selector = createSelector(
  (state:StateContext["state"]) => state.needles,
  (state:StateContext["state"]) => state.isDropdownOpen,
  (needles, open ) => ({ needles, open })
);


const actions = (dispatch:ThunkDispatch)=>({
  REDIRECT: ( url: string )=>dispatch(REDIRECT( url )),
  IS_DROPDOWN_OPEN: ( isOpen: boolean )=>dispatch(IS_DROPDOWN_OPEN( isOpen ))
})

export default connect(Context, selector, actions)(NeedleDropdown);