import React, { useMemo } from "react";
import { connect } from "../redux";
import { Context } from "../state";
import { createSelector } from "reselect";
import Tweet from './Tweet';
import Loading from './Loading';
import styled, { createGlobalStyle, keyframes, css, FlattenSimpleInterpolation } from "styled-components";
import { getString } from "../../shared/utils/color";
// @ts-ignore: @types not up to date;
import { CSSTransition, SwitchTransition } from "react-transition-group";

declare const gtag: Function;

const followLink = `https://twitter.com/intent/follow/?user_id=1034753995821248513`;
const GlobalStyles = createGlobalStyle<{ backgroundColor: number, animation: FlattenSimpleInterpolation }>`
  body {
    padding:0;
    margin:0;
    transition:background-color 0.3s;
    background-color: ${({backgroundColor})=>getString(backgroundColor)};
    overflow:hidden;
  }
  #root {
    position:absolute;
    width:100%;
    height:100%;
    overflow:hidden;
  }
  * {
    -webkit-tap-highlight-color: transparent;
    outline:0;
    user-select:none;
  }
`;

const TransitionStyles = createGlobalStyle`
  .tweet-enter {
    opacity: 0;
  }
  .tweet-enter-active {
    opacity: 1;
    pointer-events:none;
    transition: opacity 300ms;
  }
  .tweet-enter-done {
    opacity: 1;
  }
  .tweet-exit {
    opacity: 1;
  }
  .tweet-exit-active {
    opacity: 0;
    pointer-events:none;
    transition: opacity 300ms;
  }
  .tweet-exit-done {
    opacity: 0;
  }
`;

const getKeyframes = (): string =>{
  let keyframes = ``;
  for(let i=0; i<=100; i++) {
    keyframes += `${i}% { background-color: ${getString(3.6 * i)}; } `;
  }
  return keyframes;
}
const loadingKeyframes = keyframes`${getKeyframes()}`;

type ContextProps = {
  isTweetAvailable: boolean;
  current: State["current"];
  displayCount: number;
  nextId?: string;
};
type OwnProps = {};

type Props = OwnProps & ContextProps ;

function Root(props:Props){
  // clear out initial <head> from server
  useMemo(()=>{
    const children = document.head.children;
    const toRemove = [];
    for(let i=0; i<children.length; i++) {
      const child = children[i];
      if(
        !child.hasAttribute("data-preserve")
        && !child.hasAttribute("data-styled")
      ) { 
        toRemove.push(child)
      }
    }
    toRemove.forEach((child)=>document.head.removeChild(child));
  },[]);
  const animationStartColor = props.current.color || 0;
  const animationDuration = 10;
  const animationDelay = animationDuration/100*(animationStartColor*100/360);

  return (<>
    <TransitionStyles />
    <Follow
      visible= {props.displayCount > 0}
      reduce= {props.displayCount > 3}
      textColor={props.current.color as number}
      onMouseUp={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        gtag('event', 'click', {
          event_category: 'useraction',
          event_label: 'follow',
          value: props.current.id || "undefined"
        })
        window.open(followLink, "", "width=500px,height=520px,scrollbars=no");
      }}
      onTouchEnd={(e)=>{
        e.stopPropagation();
        e.preventDefault();
        gtag('event', 'click', {
          event_category: 'useraction',
          event_label: 'follow',
          value: props.current.id || "undefined"
        })
        window.location.href=followLink;
      }}
    >
      <FollowIcon /> <span>follow</span> @hopefearlove
    </Follow>
    <SwitchTransition>
      <CSSTransition key={ props.current.id || "loading" } timeout={300} classNames="tweet">
        { props.isTweetAvailable ?
            <Tweet {...props.current} /> 
          : <Loading />
        }
      </CSSTransition>
    </SwitchTransition>
    {props.current.color &&
      <GlobalStyles 
        backgroundColor={props.current.color as number}  
        animation={css`${loadingKeyframes} ${animationDuration}s linear -${animationDelay}s ${props.isTweetAvailable ? "0": "infinite"}`}
      />
    }
  </>);
}

const selector = createSelector(
  (state:State)=>Boolean(state.current.id && state.tweets[state.current.id]),
  (state:State)=>state.current,
  (state:State)=>state.displayCount,
  (state:State) => (
    (
      state.current.needle 
      && state.tweetQueue[state.current.needle]
      && state.tweetQueue[state.current.needle][0]
    )
    || undefined
  ),
  (isTweetAvailable, current, displayCount, nextId) => ({isTweetAvailable, current, displayCount, nextId})
)

const Follow = styled.a<{ textColor:number; visible: boolean; reduce: boolean }>`
  position:absolute;
  top:25px;
  right:0px;
  transform:${({visible})=>visible?`translateX(0%)`:`translateX(101%)`} ${({reduce})=>reduce?`translateY(-10px)`:``};
  font-size: 16px;
  font-family: Brandon Text W01 Medium,Helvetica,Arial,sans-serif;
  color: #fff;
  padding: 5px 24px 6px 35px;
  transition: color .25s, background-color .25s, transform .5s ease .5s;
  user-select: none;
  cursor: pointer;
  border-radius:3px 0px 0px 3px;
  border:none;
  outline:none;
  pointer-events: auto;
  margin:0;
  background:${({reduce})=>reduce?"transparent":"rgba(255,255,255, 0.9)"};
  color:${({textColor, reduce})=>reduce?"rgba(255,255,255, 0.4)":getString(textColor)};
  z-index:10;
  
  & span {
    font-family:"Brandon Text W01 Thin",Helvetica Light,Arial,sans-serif;
  }

  &:active {
    color:${({textColor})=>getString(textColor)};
    background:rgba(255,255,255, 0.7) !important;
  }

  &:hover {
    color:${({textColor})=>getString(textColor)};
    background:rgba(255,255,255, 1) !important;
  }
`
const Icon = styled.span`
  display: block;
  font-size: 18px;
  position: absolute;
  left: 12px;
  bottom: 4px;

  &::before {
    position:absolute;
    overflow:hidden;
    bottom:3px;
    width:16px; 
    display: block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
`
const FollowIcon = styled(Icon)`
  left: 10px;
  bottom:4px;

  &::before {
    content: "\f243";
  }
`;


export default connect(Context, selector)(Root);

