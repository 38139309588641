import React, { SyntheticEvent } from 'react';
import styled, { keyframes, css } from 'styled-components';

type Props = {}

function Loading(props:Props){
  return (
    <Root>
      <Heart />
    </Root>
  );
}
const heartBeatAnimation = keyframes`
  from {
    opacity:0;
    transform: scale3d(0.8, 0.8, 0.8);
  }
  10%{
    opacity:0;
    transform: scale3d(0.8, 0.8, 0.8);
  }
  45% {
    opacity:1;
    transform: scale3d(1.4, 1.4, 1.4);
  }
  90% {
    opacity:0;
  }
  to {
    opacity:0;
    transform: scale3d(0.8, 0.8, 0.8);
  }
`
const Heart = styled.span`
  display: block;
  font-size: 50px;
  color:#ffffff;
  text-align:center;

  animation-fill-mode: both;
  animation-iteration-count:infinite;
  animation-duration: 2300ms;
  animation-delay:500ms;
  animation-name: ${heartBeatAnimation};
  animation-timing-function: ease-out;

  &::before {
    content: "\\F388";
    position:absolute;
    overflow:hidden;
    top:-25px;
    left:-25px;
    height:50px;
    width:50px; 
    display: block;
    font-family: Ionicons;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
`

const Root = styled.div`
  position:absolute;
  display:flex;
  height:100%;
  width:100%;
  flex-direction:column;
  align-items:flex-start;
  flex:none;
  color:#fff;
  justify-content: center;
  align-items:center;
`;

export default Loading;