module.exports = {
  getHSL,
  getNextNeedleColor,
  getNextTweetColor,
  getRandomColor,
  getString
}

///////////////////////////////////////////////////////////////////////////////
function getHSL(H){
	H = (H % 360)+1;	
	return {
	  h: H,
	  s: map(Math.abs(H-90-60) , 0, 180, 55, 65),
	  l: map(Math.abs(H-180-60) , 0, 180, 65, 55)
	}
}
///////////////////////////////////////////////////////////////////////////////
function getString(H){
  const hsl = getHSL(H);
	return `hsl(${hsl.h}, ${hsl.s}%, ${hsl.l}%)`;
}
///////////////////////////////////////////////////////////////////////////////
function getRandomColor(){
	return Math.floor(Math.random()*360)+1
}
///////////////////////////////////////////////////////////////////////////////
function getNextTweetColor(H){
  return (H + 5 + Math.floor(Math.random()*10))%360;
}
///////////////////////////////////////////////////////////////////////////////
function getNextNeedleColor(H){
  return (H + 90 + Math.floor(Math.random()*180))%360;
}
///////////////////////////////////////////////////////////////////////////////

function map(value, in_min , in_max , out_min , out_max ) {
  return ( value - in_min ) * ( out_max - out_min ) / ( in_max - in_min ) + out_min;
}